import DOMPurify from 'dompurify';
import { Base64 } from 'js-base64';

function sanitizeHtml(html) {
    return { __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }) };
}

function isHTML(str) {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

function isBase64(str) {
    const base64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64Regex.test(str);
}

function base64ToHtml(str) {
    return isBase64(str) ? Base64.decode(str) : str;
}

function htmlToBase64(str) {
    return Base64.encode(str);
}

export {
    sanitizeHtml,
    isHTML,
    base64ToHtml,
    htmlToBase64,
};
